import React, { Fragment } from 'react'
import { Navbar, Nav } from 'react-bootstrap'

import Form from 'react-bootstrap/Form'

import { InstanceName } from 'components/Login'
import { sendReset } from 'API/user'
const forgetpassword = '/forgetpassword.png'
const Image = '/forgot_password.svg'
function Email() {
	const [email, setEmail] = React.useState()
	const [sent, setSent] = React.useState(false)
	return (
		<Fragment>
			<Navbar bg='white' className='navbarMail px-lg-5' expand='lg'>
				<Navbar.Brand className='navbarBrand' href='/'>
					<InstanceName></InstanceName>
				</Navbar.Brand>
			</Navbar>

			<div className='email-container'>
				<div className='row px-md-2 justify-content-center row-emailContent'>
					<div className='col-md-6 col-emailContent'>
						{/* <img src={forgetpassword} alt='' /> */}
						<h3>Forgot Password?</h3>
						<img src={forgetpassword} className='img-fluid' alt='Responsive image' />
						{!sent && (
							// <>
							// 	<p className='mb-2'>Enter your email and we {"'"} ll send you instructions to reset your password</p>
							// 	<form
							// 		onSubmit={async (e) => {
							// 			e.preventDefault()
							// 			if (window !== undefined) {
							// 				const url = window.location.protocol + '//' + window.location.hostname
							// 				await sendReset(email, url)
							// 				setSent(true)
							// 			}
							// 		}}
							// 	>
							// 		<Form.Group controlId='formBasicEmail'>
							// 			{/* <Form.Label className="text-dark">Email</Form.Label> */}
							// 			<input
							// 				onChange={(e) => setEmail(e.target.value)}
							// 				type='email'
							// 				placeholder='Enter email'
							// 				value={email}
							// 				autoComplete='email'
							// 				className='input-field form-control'
							// 			/>

							// 			<Form.Text className='text-muted'>We{"'"}ll never share your email with anyone else.</Form.Text>
							// 		</Form.Group>
							// 		<input
							// 			onClick={async () => {
							// 				// const data = await axios.post(resolveUrl(`auth/send_mail`), {
							// 				// 	mail: email,
							// 				// 	link: window.location.origin.toString(),
							// 				// })
							// 				// console.log(data)
							// 			}}
							// 			type='submit'
							// 			value='Send reset link'
							// 			className='btn btn-submit btn-primary rounded mt-2'
							// 		/>
							// 	</form>
							// </>
							<></>
						)}
						{sent && <p className='mb-2'>Please check your email for reset password instructions.</p>}
						<Nav.Link className='navLink' href='/'>
							<i className='bx bx-chevron-left'></i> Back to login
						</Nav.Link>
					</div>
					<div className='col-md-6 order-first order-md-last emailImg'>
						<div className='text-center'>
							<img src={Image} alt='' />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}
export default Email
